declare global {
    // eslint-disable-next-line
    interface Window extends WeblabsWindow {}
}

export interface WeblabsWindow {
    renderingWeblabs?: { [key: string]: string };
}

export class Weblab {
    constructor(
        private readonly weblabsWindow: WeblabsWindow,
        private readonly prefix: string,
    ) {}

    isT1 = (): boolean => {
        return this.weblabTreatment() === 't1';
    };

    isT2 = (): boolean => {
        return this.weblabTreatment() === 't2';
    };

    isT3 = (): boolean => {
        return this.weblabTreatment() === 't3';
    };

    isT4 = (): boolean => {
        return this.weblabTreatment() === 't4';
    };

    isT5 = (): boolean => {
        return this.weblabTreatment() === 't5';
    };

    private weblabTreatment = (): string | undefined => {
        return this.weblabsWindow?.renderingWeblabs?.[this.prefix]?.toLowerCase();
    };
}

/*
 * Gating weblab for ADSP video rendering
 * @link  https://weblab.amazon.com/wl/ADPT_SF_ADSP_VIDEO_833419
 */
export const ADPT_SF_ADSP_VIDEO_833419 = () => new Weblab(window, 'ADPT_SF_ADSP_VIDEO_833419');

/*
 * Will increase the amount of errors logged for transparency information missing
 * @link  https://weblab.amazon.com/wl/ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU_712921
 */
export const ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU = () =>
    new Weblab(window, 'ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU_712921');

/*
 * Gonna be used for some general light ads refactorings
 * @link  https://weblab.amazon.com/wl/ADPT_SF_LIGHTADS_REFACTOR_903064
 */
export const ADPT_SF_LIGHTADS_REFACTOR = () => new Weblab(window, 'ADPT_SF_LIGHTADS_REFACTOR_903064');

/*
 * Resize reimplementation using aspect ratio
 * @link  https://weblab.amazon.com/wl/ADPT_SF_RESIZE_ASPECT_RATIO_909226
 */
export const ADPT_SF_RESIZE_ASPECT_RATIO = () => new Weblab(window, 'ADPT_SF_RESIZE_ASPECT_RATIO_909226');

/*
 * Experiment for determining new pixel logic's revenue impact
 * @link  https://weblab.amazon.com/wl/SAFEFRAME_NEW_PIXEL_FIRING_922534
 */
export const SAFEFRAME_NEW_PIXEL_FIRING = () => new Weblab(window, 'SAFEFRAME_NEW_PIXEL_FIRING_922534');

/*
 * Used for gating IN art event styling
 * @link https://weblab.amazon.com/wl/ADPT_SF_HOMEPAGE_CARD_THEMING_959278
 */
export const ADPT_SF_HOMEPAGE_CARD_THEMING = () => new Weblab(window, 'ADPT_SF_HOMEPAGE_CARD_THEMING_959278');

/*
 * Used for gating IN art event styling
 * @link https://weblab.amazon.com/wl/ADPT_SF_ADREPORTER_REFACTOR_973426
 */
export const ADPT_SF_ADREPORTER_REFACTOR = () => new Weblab(window, 'ADPT_SF_ADREPORTER_REFACTOR_973426');

/*
 * Use for gating timeout from waitUntilElementExists
 * @link
 */
export const ADPT_SF_WAITUNTILELEMENT_TIMEOUT = () => new Weblab(window, 'ADPT_SF_WAITUNTILELEMENT_TIMEOUT_990056');
